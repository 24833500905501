<template>
  <b-card
      v-if="isMounted && order.number != null"
      class="card-transaction"
      no-body
  >
    <!--    Purchase order-->
    <b-card-header class="pb-50">
      <b-card-title>{{ $t('PurchaseOrder') }}</b-card-title>
    </b-card-header>
    <b-card-body>
      <content-document
          v-if="'id' in order && order.number != null"
          :document="{extension: 'pdf', name: $t('PurchaseOrder')}"
          :isDeletable="false"
          @click="$emit('downloadOrder', order)"
          @download="$emit('downloadOrder', order)"
      />
    </b-card-body>

    <!--    Documents-->
    <b-card-header
        v-if="order.documents.length"
        class="pb-50"
    >
      <b-card-title>{{ capitalize($tc('document', 2)) }}</b-card-title>
    </b-card-header>
    <b-card-body
        v-if="order.documents.length">
      <content-document
          v-for="(document) in order.documents"
          :document="document"
          :isDeletable="false"
      />
    </b-card-body>

    <!--    Signed document-->
    <b-card-header
        v-if="order.signedDocument"
        class="pb-50">
      <b-card-title>{{ capitalize($t('signedDocument')) }}</b-card-title>
    </b-card-header>
    <b-card-body
        v-if="order.signedDocument"
    >
      <content-document
          :document="order.signedDocument"
          :isDeletable="false"
          @click="download(order.signedDocument)"
      />
    </b-card-body>

    <!--    Invoices-->
    <b-card-header
        v-if="order._linkedIncomingInvoices.length"
        class="pb-50">
      <b-card-title>{{ capitalize($tc('invoice', 2)) }}</b-card-title>
    </b-card-header>
    <b-card-body
        v-if="order._linkedIncomingInvoices.length">
      <content-incoming-invoice
          v-for="(incomingInvoice, index) in order._linkedIncomingInvoices"
          :incomingInvoice="incomingInvoice"
          :isEditable="order.isArchived?false:true"
          :isDeletable="order.isArchived?false:true"
          @edit="$emit('editIncomingInvoice', incomingInvoice)"
          @delete="$emit('deleteIncomingInvoice', index)"
          class="mb-1"
      />
    </b-card-body>
  </b-card>

</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '@/utils/filter'

import CardDocument from '@/components/card/Document'
import ContentDocument from '@/components/card/components/DocumentContent'
import ContentIncomingInvoice from '@/components/card/components/IncomingInvoiceContent'

export default {
  components: {
    CardDocument,
    ContentDocument,
    ContentIncomingInvoice
  },
  props: {
    order: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const isMounted = computed(() => {
      return ('discount' in props.order)
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const download = (documentToDownload) => {
      let link = document.createElement('a')
      link.setAttribute('target', '_blank')
      link.href = documentToDownload.url
      link.click()
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data

      // Computed
      isMounted,

      // Methods
      download,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>