<template>
  <b-row>
    <b-col :md="order.workflow==null?12:6">
      <field-select
          :model.sync="order.workflow"
          :name="'workflow'"
          :options="workflows"
          label="_display"
          class="w-100"
          @input="resetOrderPhase"
      />
    </b-col>

    <b-col md="6"
           v-if="order.workflow">
      <field-select
          :model.sync="order.phase"
          :name="'phase'"
          :placeholder="$t('Choose a phase')"
          :options="availablePhases"
          :label="'name'"
          class="w-100"
          :key="componentKey"
      />
    </b-col>
  </b-row>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { isObject } from '../../../../utils/utils'

import FieldSelect from '@/components/input/Select'

export default {
  components: {
    FieldSelect
  },
  props: {
    order: {
      type: Object,
      default: () => {}
    },
    workflows: {
      type: Array,
      default: () => []
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const componentKey = ref(0)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const availablePhases = computed(() => {
      if (isObject(props.order.workflow)) {
        return props.workflows.find(w => w.id == props.order.workflow.id).phases
      } else if (props.workflows.some(w => w.id == props.order.workflow)) {
        return props.workflows.find(w => w.id == props.order.workflow).phases
      } else {
        return []
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const resetOrderPhase = () => {
      props.order.phase = null
      componentKey.value++
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      componentKey,

      // Computed
      availablePhases,

      // Methods
      resetOrderPhase,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>