<template>
  <b-row>
    <!--    payment terms-->
    <b-col md="6">
      <field-select
          :model.sync="order.paymentTerm"
          :name="'paymentTerms'"
          :options="paymentTerms"
          :label="'term'"
          :isRequired="true"
          :clearable="false"
      />
    </b-col>

    <!--    payment method-->
    <b-col md="6">
        <field-select
            :model.sync="order.paymentMethod"
            :name="'paymentMethod'"
            :options="paymentMethods"
            :label="'name'"
            :isRequired="true"
            :clearable="false"
        />
    </b-col>
  </b-row>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import useAPI from '@/utils/useAPI'
import FieldSelect from '@/components/input/Select'

export default {
  components: {
    FieldSelect
  },
  props: {
    order: {
      type: Object,
      default: () => {}
    }
  },
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { paymentMethods, paymentTerms } = useAPI()

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed
      paymentMethods,
      paymentTerms,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>